import { MAINTAIN_PERMISSION, LINE_PERFORMANCE_QUALITY_CATEGORIES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.showTable ? _c("div", [_c("list-composer", { attrs: { "title": "Quality Categories", "has-pagination": false, "create-button-text": "Add Quality Reason", "search-by": "Code", "ph-search-by": "Search by Quality Reason", "fetch-on-first-mount": false }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e()];
  } }], null, false, 3229268394) }, [_c("text-field", { key: "Code", attrs: { "data-index": "code", "title": "Quality Reason", "sorter": true } })], 1)], 1) : _vm._e();
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListQualityReasons",
  inject: ["crud", "resourceName", "facility", "getArea", "can"],
  data() {
    const apiUrl2 = "#{VUE_APP_API_URL}#";
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      apiUrl: apiUrl2,
      showTable: false
    };
  },
  computed: {
    area() {
      return this.getArea();
    }
  },
  watch: {
    area(newVal) {
      this.crud.setFilter("AreaID", { operator: "Eq", value: newVal });
      this.fetchList();
    }
  },
  created() {
    this.crud.setFilter("AreaID", { operator: "Eq", value: this.area });
    this.fetchList();
    this.showTable = true;
  },
  methods: {
    fetchList(isCreated) {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListQualityReasons = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-quality-categories" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "identities.common.facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.quanlity-reasons", "api-url": _vm.apiUrl, "create-route": "/line-performance/quality/create", "edit-route": "/line-performance/quality/:id", "page": _vm.page } }, [_c("list-quality-reasons")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListQualityReasons
  },
  data() {
    return {
      page: LINE_PERFORMANCE_QUALITY_CATEGORIES,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "quality",
          title: "Quality Categories",
          path: "/line-performance/quality"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
